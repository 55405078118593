/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import {
  Search as SearchIcon,
  ArrowRightCircle as ArrowIcon,
  XCircle as XCircleIcon,
} from 'react-feather';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  setQuery,
  setCategory,
  setSubcategory,
  setOrder,
  setStartDate,
  setEndDate,
  setPage,
  setLimit,
  setSupermarket,
  setBrand,
  setTimeframe,
  setIsEngelProduct,
} from '../../../actions/snapsList';

import alphabeticSort from '../../../utils/alphabeticSort';
import useStyles from './styles';

const timeframeOptions = [
  {
    value: 'weekly',
    label: 'Semanal',
  },
  {
    value: 'monthly',
    label: 'Mensual',
  },
];

const isEngelProductOptions = [
  {
    value: 'true',
    label: 'Si',
  },
  {
    value: 'false',
    label: 'No',
  },
];

function Results({
  className,
  productsnapshots,
  sortOptions,
  count,
  originalCategories,
  categories,
  setCategories,
  subcategories,
  brands,
  supermarkets,
  setSubcategories,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentProductsnapshots, setCurrentProductsnapshots] = useState(productsnapshots);
  const [currentQuery, setCurrentQuery] = useState('');

  const {
    category,
    subcategory,
    supermarket,
    order,
    page,
    limit,
    startDate,
    endDate,
    brand,
    timeframe,
    isEngelProduct,
  } = useSelector((state) => state.snapsList);

  useEffect(() => { setCurrentProductsnapshots(productsnapshots); }, [productsnapshots]);

  const handleQueryChange = (event) => {
    event.persist();
    setCurrentQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    dispatch(setOrder(event.target.value));
  };

  const handlePageChange = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleLimitChange = (event) => {
    dispatch(setLimit(event.target.value));
  };

  const handleChange = (type, newVal) => {
    if (!!newVal) {
      const ides = newVal.map((val) => val.id);
      dispatch(setPage(0));
      if (type === 'supermarket') {
        dispatch(setSupermarket(newVal));
        dispatch(setCategory([]));
        dispatch(setSubcategory([]));
        let subcatList = [];
        if (ides.length === 0) {
          setCategories(originalCategories);
          originalCategories.forEach((element) => {
            subcatList = subcatList.concat(element.Subcategories);
          });
        } else {
          const filteredCats = originalCategories.filter((item) => ides.includes(item.supermarket_id)).sort((a, b) => alphabeticSort(a, b));
          setCategories(filteredCats);
          filteredCats.forEach((element) => {
            subcatList = subcatList.concat(element.Subcategories);
          });
        }
        setSubcategories(subcatList.sort((a, b) => alphabeticSort(a, b)));
      } else if (type === 'category') {
        let subcatList = [];
        dispatch(setCategory(newVal));
        dispatch(setSubcategory([]));
        if (ides.length === 0) {
          originalCategories.forEach((element) => {
            subcatList = subcatList.concat(element.Subcategories);
          });
        } else {
          categories.forEach((element) => {
            if (ides.includes(element.id)) {
              subcatList = subcatList.concat(element.Subcategories);
            }
          });
        }
        setSubcategories(subcatList.sort((a, b) => alphabeticSort(a, b)));
      }
    }
  };

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box p={2}>
        <Grid container spacing={1}>
          <Grid item xs={2} md={4}>
            <TextField
              className={classes.queryField}
              onChange={handleQueryChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
                endAdornment: currentQuery ? (
                  <IconButton size="small" onClick={() => {
                    setCurrentQuery('');
                    dispatch(setQuery(''));
                  }}>
                    <XCircleIcon />
                  </IconButton>
                ) : null,
              }}
              placeholder="Buscar"
              value={currentQuery}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={0.5} md={0.5} mr={2}>
            <Button
              color="secondary"
              variant="contained"
              className={classes.searchAction}
              onClick={() => dispatch(setQuery(currentQuery))}
            >
              <SvgIcon >
                <SearchIcon />
              </SvgIcon>
            </Button>
          </Grid>
          <Grid item xs={1} md={2} >
            <TextField
              className={classes.queryField}
              label="Periodicidad"
              name="timeframe"
              onChange={(e) => dispatch(setTimeframe(e.target.value))}
              select
              SelectProps={{ native: true }}
              value={timeframe}
              variant="outlined"
            >
              {timeframeOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={1} md={2} >
            <TextField
              className={classes.queryField}
              label="Producto Engel"
              name="isEngelProduct"
              onChange={(e) => dispatch(setIsEngelProduct(e.target.value))}
              select
              SelectProps={{ native: true }}
              value={isEngelProduct}
              variant="outlined"
            >
              {isEngelProductOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid
            item

            xs={2}
            align='right'
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Desde"
                inputFormat="MM/dd/yyyy"
                value={startDate}
                onChange={(e) => dispatch(setStartDate(e.valueOf()))}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={2}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Hasta"
                inputFormat="MM/dd/yyyy"
                value={endDate}
                onChange={(e) => dispatch(setEndDate(e.valueOf()))}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>

      <Box p={2}>
        <Grid container spacing={1} >
          <Grid item xs={2} md={2}>
            {supermarkets.length > 0 && <Autocomplete
              className={classes.queryField}
              id="supermarkets-autocomplete"
              options={supermarkets}
              multiple
              value={supermarket}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChange('supermarket', v)}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Supermercados'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
            />
            }
          </Grid>
          <Grid item xs={2} md={3}>
            {categories.length > 0 && <Autocomplete
              className={classes.queryField}
              id="categories-autocomplete"
              options={categories}
              multiple
              value={category}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChange('category', v)}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Categoría'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    key={option.id}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name} ({supermarkets.filter((sup) => sup.id === option.supermarket_id)[0].name})
                </li>
              )}
            />
            }
          </Grid>
          <Grid item xs={2} md={3}>
            <Autocomplete
              className={classes.queryField}
              id="subcategories-autocomplete"
              options={subcategories}
              multiple
              value={subcategory}
              getOptionLabel={(option) => `${option.name} (${option.id.toString()})`}
              onChange={(e, v) => dispatch(setSubcategory(v))}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Subcategoría'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    key={option.id}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}  ({supermarkets.filter((sup) => sup.id === option.supermarket_id)[0].name})
                </li>
              )}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            {brands.length > 0 && <Autocomplete
              className={classes.queryField}
              id="brands-autocomplete"
              options={brands}
              multiple
              value={brand}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => dispatch(setBrand(v))}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Marcas'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    key={option.id}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
            />
            }
          </Grid>
          <Grid item xs={2} md={2}>
            <TextField
              className={classes.queryField}
              label="Orden"
              name="order"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={order}
              variant="outlined"
            >
              {sortOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Box>

      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  Fecha
                </TableCell>
                <TableCell align='center'>
                  Supermercado
                </TableCell>
                <TableCell align='center'>
                  Sku
                </TableCell>
                <TableCell align='center'>
                  Descripcion
                </TableCell>
                <TableCell align='center'>
                  Marca
                </TableCell>
                <TableCell align='center'>
                  Unidades
                </TableCell>
                <TableCell align='center'>
                  Precio
                </TableCell>
                <TableCell align='center'>
                  Precio Oferta
                </TableCell>
                <TableCell align='center'>
                  Precio Unitario
                </TableCell>
                <TableCell align='center'>
                  Stock
                </TableCell>
                <TableCell align='center'>
                  Ver Gráficos
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentProductsnapshots.map((productsnapshot) => (
                <TableRow
                  hover
                  key={productsnapshot.id}
                >
                  <TableCell>{moment(productsnapshot.created_at).format('DD/MM/YYYY')}</TableCell>
                  <TableCell align='center'>{productsnapshot.Product.supermarket_name}</TableCell>
                  <TableCell>{productsnapshot.Product.sku}</TableCell>
                  <TableCell>{productsnapshot.Product.description}</TableCell>
                  <TableCell align='center'>{productsnapshot.Product.brand}</TableCell>
                  <TableCell align='center'>{productsnapshot.Product.units_per_product}</TableCell>
                  <TableCell align='center'>{productsnapshot.price > 0 ? productsnapshot.price : '-'}</TableCell>
                  <TableCell align='center'>{productsnapshot.sale ? productsnapshot.sale_price : '-'}</TableCell>
                  <TableCell align='center'>{
                    Math.round(productsnapshot.sale ? productsnapshot.sale_price / productsnapshot.Product.units_per_product
                      : productsnapshot.price / productsnapshot.Product.units_per_product, 3)
                  }</TableCell>
                  <TableCell align='center'>{productsnapshot.available ? 'Stock' : 'Sin Stock'}</TableCell>

                  <TableCell align='center'>
                    <IconButton
                      component={RouterLink}
                      to={`/datos/products/${productsnapshot.Product.id}/graphs`}
                    >
                      <SvgIcon fontSize="small">
                        <ArrowIcon />
                      </SvgIcon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={(page > 0 && currentProductsnapshots.length < limit) ? 0 : page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  productsnapshots: PropTypes.array,
  sortOptions: PropTypes.array,
  count: PropTypes.number,
  originalCategories: PropTypes.array,
  brands: PropTypes.array,
  categories: PropTypes.array,
  setCategories: PropTypes.func,
  supermarkets: PropTypes.array,
  subcategories: PropTypes.array,
  setSubcategories: PropTypes.func,
  productSnapshots: PropTypes.array,
  isEngel: PropTypes.bool,
  setIsEngel: PropTypes.func,
};

Results.defaultProps = {
  productsnapshots: [],
};

export default Results;
