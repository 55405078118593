import axios from '../../utils/axios';
// import { INTERNAL_SERVER_ERROR } from '../../constants/http';

const getAllBrands = async () => {
  try {
    const { data } = await axios.get('/api/brands/all');

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return err.response.data;
  }
};

const updateBrands = async () => {
  try {
    const { data } = await axios.put('/api/brands/update');

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return err.response.data;
  }
};

export {
  getAllBrands,
  updateBrands,
};
