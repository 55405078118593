import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
  CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getProductsPerPage } from '../../../requests/api/products';
import { getAllSupermarkets } from '../../../requests/api/supermarkets';
import { getAllCategoriesAndSubCategories, getAllCategoriesAndSubCategoriesByUserId } from '../../../requests/api/categories';
import { getAllBrands } from '../../../requests/api/brands';

import useStyles from './styles';
import alphabeticSort from '../../../utils/alphabeticSort';

import {
  setProductQuery,
  setProductSupermarket,
  setProductCategory,
  setProductSubcategory,
  setProductPage,
  setProductLimit,
} from '../../../actions/productsList';

const sortOptions = [
  {
    value: 'ALPH',
    label: 'Alfabético',
  },
  {
    value: 'DESC',
    label: 'Más nuevo primero',
  },
  {
    value: 'ASC',
    label: 'Más antiguo primero',
  },
];

function ProductListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [supermarkets, setSupermarkets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const {
    productSupermarket,
    productCategory,
    productSubcategory,
    productBrand,
    productQuery,
    productPage,
    productLimit,
  } = useSelector((state) => state.productsList);

  const account = useSelector((state) => state.account);

  const cleanFilters = () => {
    dispatch(setProductPage(0));
    dispatch(setProductLimit(10));
    dispatch(setProductQuery(''));
    dispatch(setProductSupermarket([]));
    dispatch(setProductCategory([]));
    dispatch(setProductSubcategory([]));
    setSort(sortOptions[0].value);
  };

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getCategories = async () => {
        let response;
        if (account.user.role === 'external' && account.user.id) {
          response = await getAllCategoriesAndSubCategoriesByUserId(account.user.id);
        } else {
          response = await getAllCategoriesAndSubCategories();
        }
        if (response.success) {
          const data = await response?.data?.data;
          setOriginalCategories(data.sort((a, b) => alphabeticSort(a, b)));
          let subcatList = [];
          let filteredCats = [];
          if (productSupermarket.length > 0) {
            const ides = productSupermarket.map((sup) => sup.id);
            filteredCats = data.filter(
              (item) => ides.includes(item.supermarket_id),
            ).sort(
              (a, b) => alphabeticSort(a, b),
            );
            setCategories(filteredCats);
            filteredCats.forEach((element) => {
              subcatList = subcatList.concat(element.Subcategories);
            });
          } else {
            setCategories(data.sort((a, b) => alphabeticSort(a, b)));
          }
          if (productCategory.length > 0) {
            subcatList = [];
            const ides = productCategory.map((cat) => cat.id);
            data.forEach((element) => {
              if (ides.includes(element.id)) {
                subcatList = subcatList.concat(element.Subcategories);
              }
            });
          } else {
            data.forEach((element) => {
              subcatList = subcatList.concat(element.Subcategories);
            });
          }
          setSubcategories(subcatList.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getCategories();
    } catch {
      setCategories([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getBrands = async () => {
        const response = await getAllBrands();
        if (response.success) {
          const data = await response?.data?.data;
          setBrands(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getBrands();
    } catch {
      setBrands([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getProducts = async () => {
        setLoading(true);
        const subIdes = productSubcategory.map((val) => val.id);
        const catIdes = productCategory.map((val) => val.id);
        const supIdes = productSupermarket.map((val) => val.id);
        const brandIdes = productBrand.map((val) => val.id);
        const userRole = account.user.role;
        const userId = account.user.id;
        const response = await getProductsPerPage(
          productPage,
          productLimit,
          productQuery,
          sort,
          supIdes,
          catIdes,
          subIdes,
          brandIdes,
          userRole,
          userId,
          false,
        );
        if (response.success) {
          const data = await response?.data?.data;
          const arrayData = data?.products ? Object.values(data?.products) : null;
          setProducts(arrayData);
          setCount(data?.totalProducts);
        } else {
          enqueueSnackbar('Error al cargar la información de los productos.', {
            variant: 'error',
          });
        }
      };
      getProducts();
    } catch {
      setProducts([]);
    }
    setLoading(false);
  }, [
    productPage,
    productLimit,
    productQuery,
    sort,
    productSupermarket,
    productCategory,
    productSubcategory,
    productBrand]);

  if (loading) {
    return (<CircularProgress style={{ color: 'var(--brand-blue' }} />);
  }

  return (
    <Page
      className={classes.root}
      title="FH Engel | Productos"
    >
      <Container maxWidth={false}>
        <Header cleanFilters={cleanFilters}/>
        {products && (
          <Box mt={3}>
            <Results
              products={products}
              sort={sort}
              setSort={setSort}
              sortOptions={sortOptions}
              count={count}
              supermarkets={supermarkets}
              originalCategories={originalCategories}
              categories={categories}
              brands={brands}
              setCategories={setCategories}
              subcategories={subcategories}
              setSubcategories={setSubcategories}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default ProductListView;
